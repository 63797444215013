
import { Navbar } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import ColorPicker from '../components/ColorPicker.vue'

export default defineComponent({
  components: {
    ColorPicker,
  },
  setup(_, { emit }) {
    const store = useStore()
    const navbarInfo = ref(store.state.config.general.navbar as Navbar)
    if (!navbarInfo.value.items) navbarInfo.value.items = []
    const addItem = () => {
      navbarInfo.value.items?.push({ title: '', link: '', color: 'primary' })
    }
    const removeItem = (index: number) => {
      navbarInfo.value.items?.splice(index, 1)
    }
    const submitData = (e: Event) => {
      e.preventDefault()
      emit('update', 'navbar', navbarInfo.value)
    }
    return {
      navbarInfo,
      addItem,
      removeItem,
      submitData,
    }
  },
})
