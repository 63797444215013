<template>
  <form class="box" @submit="submitData">
    <h3 class="title is-4">Header</h3>
    <div class="field">
      <label class="label">Header Title</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Header Title"
          v-model="headerInfo.title"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Header Subtitle</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Header Subtitle"
          v-model="headerInfo.subtitle"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Header Size</label>
          <div class="control select">
            <select v-model="headerInfo.size">
              <option
                v-for="(size, index) in sizeList"
                :key="index"
                :value="size"
              >
                {{ size }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Header Color</label>
          <div class="control">
            <ColorPicker
              :color="headerInfo.color"
              @update="(color) => (headerInfo.color = color)"
            />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="field">
      <button class="button is-primary" type="submit">Save</button>
    </div>
  </form>
</template>

<script lang="ts">
import { Header } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import ColorPicker from '../components/ColorPicker.vue'

export default defineComponent({
  components: { ColorPicker },
  setup(_, { emit }) {
    const store = useStore()
    const headerInfo = ref(store.state.config.general.header as Header)
    const sizeList = ref(['small', 'medium', 'large'])
    const submitData = (e: Event) => {
      e.preventDefault()
      emit('update', 'header', headerInfo.value)
    }
    return {
      headerInfo,
      submitData,
      sizeList,
    }
  },
})
</script>
