
import { Basic } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(_, { emit }) {
    const store = useStore()
    const basicInfo = ref(store.state.config.general.basic as Basic)
    if (!basicInfo.value.meta) basicInfo.value.meta = []
    const addMetaTag = () => {
      basicInfo.value.meta?.push({ name: '', content: '' })
    }
    const removeMetaTag = (index: number) => {
      basicInfo.value.meta?.splice(index, 1)
    }
    const submitData = (e: Event) => {
      e.preventDefault()
      emit('update', 'basic', basicInfo.value)
    }
    return {
      basicInfo,
      removeMetaTag,
      addMetaTag,
      submitData,
    }
  },
})
