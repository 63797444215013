<template>
  <form class="box" @submit="submitData">
    <h3 class="title is-4">Basic Information</h3>
    <div class="field">
      <label class="label">App Title</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="App Title"
          required
          v-model="basicInfo.title"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Meta Tags</label>
      <div class="columns" v-for="(tag, index) in basicInfo.meta" :key="index">
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Name"
                v-model="basicInfo.meta[index].name"
              />
            </div>
          </div>
        </div>
        <div class="column is-8">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Content"
                v-model="basicInfo.meta[index].content"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <button
                class="button is-danger"
                type="button"
                @click="removeMetaTag(index)"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <button class="button is-link" type="button" @click="addMetaTag">
          Add
        </button>
      </div>
    </div>
    <hr />
    <div class="field">
      <button class="button is-primary" type="submit">Save</button>
    </div>
  </form>
</template>

<script lang="ts">
import { Basic } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(_, { emit }) {
    const store = useStore()
    const basicInfo = ref(store.state.config.general.basic as Basic)
    if (!basicInfo.value.meta) basicInfo.value.meta = []
    const addMetaTag = () => {
      basicInfo.value.meta?.push({ name: '', content: '' })
    }
    const removeMetaTag = (index: number) => {
      basicInfo.value.meta?.splice(index, 1)
    }
    const submitData = (e: Event) => {
      e.preventDefault()
      emit('update', 'basic', basicInfo.value)
    }
    return {
      basicInfo,
      removeMetaTag,
      addMetaTag,
      submitData,
    }
  },
})
</script>
