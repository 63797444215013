<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title title is-3">App Config</p>
      <button class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="container">
        <div class="block" v-if="isConfigLoaded">
          <div class="notification is-primary" v-if="isUpdated">
            <button class="delete" @click="removeNotification"></button>
            Config has been updated!
          </div>
          <BasicInformationForm @update="updateConfig" />
          <NavbarForm @update="updateConfig" />
          <HeaderForm @update="updateConfig" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { Basic, Header, Navbar } from '@/types/config'
import { useStore } from 'vuex'

import BasicInformationForm from '@/components/AdminComponents/Config/GeneralConfig/BasicInformationForm.vue'
import NavbarForm from '@/components/AdminComponents/Config/GeneralConfig/NavbarForm.vue'
import HeaderForm from '@/components/AdminComponents/Config/GeneralConfig/HeaderForm.vue'

export default defineComponent({
  components: { BasicInformationForm, NavbarForm, HeaderForm },
  setup() {
    const store = useStore()
    const isConfigLoaded = computed(() => store.state.configStatus === 'loaded')
    const isUpdated = ref(false)
    const removeNotification = () => (isUpdated.value = false)
    const updateConfig = async (
      type: string,
      data: Basic | Header | Navbar
    ) => {
      removeNotification()
      await store.dispatch('updateConfig', { type, data })
      isUpdated.value = true
    }
    return {
      isConfigLoaded,
      isUpdated,
      updateConfig,
      removeNotification,
    }
  },
})
</script>
