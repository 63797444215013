<template>
  <form class="box" @submit="submitData">
    <h3 class="title is-4">Navbar</h3>
    <div class="field">
      <div class="field">
        <label class="label">Image Link</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Image Link"
            v-model="navbarInfo.image.link"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Image Width</label>
            <div class="control">
              <input
                class="input"
                type="number"
                placeholder="Image Width"
                v-model="navbarInfo.image.width"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Image Height</label>
            <div class="control">
              <input
                class="input"
                type="number"
                placeholder="Image Height"
                v-model="navbarInfo.image.height"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Navbar Items</label>
      <div
        class="columns"
        v-for="(item, index) in navbarInfo.items"
        :key="index"
      >
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Title"
                v-model="navbarInfo.items[index].title"
              />
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Link"
                v-model="navbarInfo.items[index].link"
              />
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <ColorPicker
                :color="navbarInfo.items[index].color"
                @update="(color) => (navbarInfo.items[index].color = color)"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <button
                class="button is-danger"
                type="button"
                @click="removeItem(index)"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <button class="button is-link" type="button" @click="addItem">
          Add
        </button>
      </div>
    </div>
    <hr />
    <div class="field">
      <button class="button is-primary" type="submit">Save</button>
    </div>
  </form>
</template>

<script lang="ts">
import { Navbar } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import ColorPicker from '../components/ColorPicker.vue'

export default defineComponent({
  components: {
    ColorPicker,
  },
  setup(_, { emit }) {
    const store = useStore()
    const navbarInfo = ref(store.state.config.general.navbar as Navbar)
    if (!navbarInfo.value.items) navbarInfo.value.items = []
    const addItem = () => {
      navbarInfo.value.items?.push({ title: '', link: '', color: 'primary' })
    }
    const removeItem = (index: number) => {
      navbarInfo.value.items?.splice(index, 1)
    }
    const submitData = (e: Event) => {
      e.preventDefault()
      emit('update', 'navbar', navbarInfo.value)
    }
    return {
      navbarInfo,
      addItem,
      removeItem,
      submitData,
    }
  },
})
</script>
