
import { computed, defineComponent, ref } from 'vue'
import { Basic, Header, Navbar } from '@/types/config'
import { useStore } from 'vuex'

import BasicInformationForm from '@/components/AdminComponents/Config/GeneralConfig/BasicInformationForm.vue'
import NavbarForm from '@/components/AdminComponents/Config/GeneralConfig/NavbarForm.vue'
import HeaderForm from '@/components/AdminComponents/Config/GeneralConfig/HeaderForm.vue'

export default defineComponent({
  components: { BasicInformationForm, NavbarForm, HeaderForm },
  setup() {
    const store = useStore()
    const isConfigLoaded = computed(() => store.state.configStatus === 'loaded')
    const isUpdated = ref(false)
    const removeNotification = () => (isUpdated.value = false)
    const updateConfig = async (
      type: string,
      data: Basic | Header | Navbar
    ) => {
      removeNotification()
      await store.dispatch('updateConfig', { type, data })
      isUpdated.value = true
    }
    return {
      isConfigLoaded,
      isUpdated,
      updateConfig,
      removeNotification,
    }
  },
})
