
import { Header } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import ColorPicker from '../components/ColorPicker.vue'

export default defineComponent({
  components: { ColorPicker },
  setup(_, { emit }) {
    const store = useStore()
    const headerInfo = ref(store.state.config.general.header as Header)
    const sizeList = ref(['small', 'medium', 'large'])
    const submitData = (e: Event) => {
      e.preventDefault()
      emit('update', 'header', headerInfo.value)
    }
    return {
      headerInfo,
      submitData,
      sizeList,
    }
  },
})
